import { BASKET_PAGE_ROUTE, BLOG_DETAIL_PAGE_ROUTE, BLOG_PAGE_ROUTE, CATALOG_CATEGORY_PAGE_ROUTE, CATALOG_PAGE_ROUTE, CHECKOUT_PAGE_ROUTE, DEALER_ROUTE, ERROR_PAGE_ROUTE, EVENT_DETAIL_PAGE_ROUTE, EVENTS_PAGE_ROUTE, FORGOT_PASSWORD_ROUTE, FRANCHISE_PAGE_ROUTE, FRANCHISE_ROUTE, LOGIN_PAGE_ROUTE, MAIN_PAGE_ROUTE, ORDER_ERROR_PAGE_ROUTE, ORDER_SUCCESS_PAGE_ROUTE, PRODUCTS_DETAIL_PAGE_ROUTE, PRODUCTS_PAGE_ROUTE, PROJECTS_DETAIL_PAGE_ROUTE, PROJECTS_PAGE_ROUTE, REGISTRATION_PAGE_ROUTE, SAMPLE_PAGE_SOUTE, SEARCH_PAGE_ROUTE, SERVICES_DETAIL_PAGE_ROUTE, SERVICES_PAGE_ROUTE, USER_DASHBOARD_PAGE_ROUTE, USER_ORDER_ROUTE, USER_PAGE_ROUTE, WISHLIST_ROUTE } from "./Constants";

import { lazy } from 'react';

const MainPage = lazy(() => import('../pages/main/MainPage'));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const LogIn = lazy(() => import("../pages/auth/LogIn"));
const Registration = lazy(() => import("../pages/auth/Registration"));
const Basket = lazy(() => import("../pages/basket/Basket"));
const Checkout = lazy(() => import("../pages/basket/Checkout"));
const OrderError = lazy(() => import("../pages/basket/OrderError"));
const OrderSuccess = lazy(() => import("../pages/basket/OrderSuccess"));
const ArticleDetail = lazy(() => import("../pages/blog/ArticleDetail"));
const ArticlesPage = lazy(() => import("../pages/blog/ArticlesPage"));
const Error = lazy(() => import("../pages/error/Error"));
const EventDetail = lazy(() => import("../pages/events/EventDetail"));
const EventsPage = lazy(() => import("../pages/events/EventsPage"));
const Catalog = lazy(() => import("../pages/products/Catalog"));
const ProductDetail = lazy(() => import("../pages/products/ProductDetail"));
const ProductsPage = lazy(() => import("../pages/products/ProductsPage"));
const ProjectDetail = lazy(() => import("../pages/projects/ProjectDetail"));
const ProjectsPage = lazy(() => import("../pages/projects/ProjectsPage"));
const FranchisePage = lazy(() => import("../pages/sample/FranchisePage"));
const SamplePage = lazy(() => import("../pages/sample/SamplePage"));
const SearchPage = lazy(() => import("../pages/sample/SearchPage"));
const WishListPage = lazy(() => import("../pages/sample/WishListPage"));
const ServicesDetail = lazy(() => import("../pages/services/ServicesDetail"));
const ServicesPage = lazy(() => import("../pages/services/ServicesPage"));
const Franchise = lazy(() => import("../pages/user/Franchise"));
const DealerPage = lazy(() => import("../pages/sample/DealerPage"));
const User = lazy(() => import("../pages/user/User"));

export const publicRoutes = [
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: '/',
        Component: MainPage
    },
    {
        path: PRODUCTS_PAGE_ROUTE,
        Component: ProductsPage
    },
    {
        path: PRODUCTS_DETAIL_PAGE_ROUTE,
        Component: ProductDetail
    },
    {
        path: BLOG_PAGE_ROUTE,
        Component: ArticlesPage
    },
    {
        path: BLOG_DETAIL_PAGE_ROUTE,
        Component: ArticleDetail
    },
    {
        path: BASKET_PAGE_ROUTE,
        Component: Basket
    },
    {
        path: CHECKOUT_PAGE_ROUTE,
        Component: Checkout
    },
    {
        path: ORDER_SUCCESS_PAGE_ROUTE,
        Component: OrderSuccess
    },
    {
        path: LOGIN_PAGE_ROUTE,
        Component: LogIn
    },
    {
        path: REGISTRATION_PAGE_ROUTE,
        Component: Registration
    },
    {
        path: FORGOT_PASSWORD_ROUTE,
        Component: ForgotPassword
    },
    {
        path: ERROR_PAGE_ROUTE,
        Component: Error
    },
    {
        path: SAMPLE_PAGE_SOUTE,
        Component: SamplePage
    },
    {
        path: SEARCH_PAGE_ROUTE,
        Component: SearchPage
    },
    {
        path: EVENTS_PAGE_ROUTE,
        Component: EventsPage
    },
    {
        path: EVENT_DETAIL_PAGE_ROUTE,
        Component: EventDetail
    },
    {
        path: SERVICES_PAGE_ROUTE,
        Component: ServicesPage
    },
    {
        path: SERVICES_DETAIL_PAGE_ROUTE,
        Component: ServicesDetail
    },
    {
        path: PROJECTS_PAGE_ROUTE,
        Component: ProjectsPage
    },
    {
        path: PROJECTS_DETAIL_PAGE_ROUTE,
        Component: ProjectDetail
    },
    {
        path: WISHLIST_ROUTE,
        Component: WishListPage
    },
    {
        path: CATALOG_PAGE_ROUTE,
        Component: ProductsPage
    },
    {
        path: CATALOG_CATEGORY_PAGE_ROUTE,
        Component: Catalog
    },
    {
        path: ORDER_ERROR_PAGE_ROUTE,
        Component: OrderError
    },
    {
        path: USER_PAGE_ROUTE,
        Component: User
    },
    {
        path: FRANCHISE_ROUTE,
        Component: FranchisePage
    },
    {
        path: DEALER_ROUTE,
        Component: DealerPage
    },
]
export const privateRoutes = [

]
export const franchiseRoutes = [
    {
        path: FRANCHISE_PAGE_ROUTE,
        Component: Franchise
    },
]
