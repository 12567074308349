import React from 'react'
import { NavLink } from 'react-router-dom'
import SubDropdownMenu from '../simple/SubDropdownMenu'
import { useState } from 'react';
import { useEffect } from 'react';

const Menu = ({item}) => {
    const [items, setItems] = useState();

    useEffect(() => {
        if(item){
            setItems(item.slice(1, item.length))
        }
    }, [item])

  return (
    <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
        <div className="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu">
            <div className="offcanvas-header navbar-shadow">
                <h5></h5>
                <button className="btn-close lead" type="button" ></button>
            </div>
            <div className="offcanvas-body">
                <ul className="navbar-nav">
                    {items?.map((it, index) => 
                        <li className="nav-item dropdown new-nav-item" key={index}>
                            <NavLink className={it.items ? "nav-link dropdown-toggle" : "nav-link dropdown-toggle nav-none"} to={it.value} data-bs-toggle="dropdown">{it.title}</NavLink>
                            {it.items &&
                                <ul className="dropdown-menu">
                                    {it.items.map((el, index) =>
                                        <SubDropdownMenu key={index} item={el}/>
                                    )}
                                </ul>
                            }
                        </li>
                     )}
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Menu