import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';

const MobileFixMenu = observer(() => {
    const {app} = useContext(Context);
    const location = useLocation();

  return (
	<div className="mobile-menu d-md-none d-block mobile-cart">
		<ul>
			<li className={location.pathname === `/${app.lang}` ? 'active' : ''}>
				<NavLink to={`/${app.lang}`}>
					<i className="iconly-Home icli"></i>
					<span>{app.localizationsItems?.main}</span>
				</NavLink>
			</li>

			<li className={location.pathname === `/${app.lang}/search` ? 'active' : ''}>
				<NavLink to={`/${app.lang}/search`} className="search-box">
					<i className="iconly-Search icli"></i>
                    <span>{app.localizationsItems?.search_text}</span>
				</NavLink>
			</li>

			{app.userCabinet && <li className={location.pathname === `/${app.lang}/wishlist` ? 'active' : ''}>
				<NavLink to={`/${app.lang}/wishlist`} className="notifi-wishlist">
					<i className="iconly-Heart icli"></i>
					<span>{app.localizationsItems?.favorites}</span>
				</NavLink>
			</li>}

			<li className={location.pathname === `/${app.lang}/basket` ? 'active' : ''}>
				<NavLink to={`/${app.lang}/basket`}>
					<i className="iconly-Bag-2 icli fly-cate"></i>
					<span>{app.localizationsItems?.cart}</span>
				</NavLink>
			</li>
		</ul>
	</div>
  )
})

export default MobileFixMenu