import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import AuthServices from '../../services/general/authServices';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const UserIcon = observer(() => {
    const navigate = useNavigate();

    const {app, user} = useContext(Context);

    const logOut = async () => {
        const res = await AuthServices.logOut();

        if (res.statusCode === 200) {
            user.setUser({})
            user.removeToken()
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}`);
        }
    }

  return (
    <li className="right-side onhover-dropdown">
        <div className="delivery-login-box">
            <div className="header-icon user-icon search-icon">
				<i className="iconly-Profile icli"></i>
			</div>
        </div>

        <div className="onhover-div onhover-div-login">
            {(localStorage.getItem('token') && (localStorage.getItem('role') === "franchise")) ?
                <ul className="user-box-name">
                    <li className="product-box-contain">
                        <NavLink to={`/${app.lang}/cabinet`}>{app.localizationsItems?.profile}</NavLink>
                    </li>
                    <li className="product-box-contain" onClick={logOut}>
                        <div className='title-a'>{app.localizationsItems?.logout}</div>
                    </li>
                </ul>
                : localStorage.getItem('token') ?
                <ul className="user-box-name">
                    <li className="product-box-contain">
                        <NavLink to={`/${app.lang}/user`}>{app.localizationsItems?.profile}</NavLink>
                    </li>
                    <li className="product-box-contain" onClick={logOut}>
                        <div className='title-a'>{app.localizationsItems?.logout}</div>
                    </li>
                </ul>
                :
                <ul className="user-box-name">
                    <li className="product-box-contain">
                        <NavLink to={`/${app.lang}/login`}>{app.localizationsItems?.login}</NavLink>
                    </li>
        
                    {!app.registerByPhone && <li className="product-box-contain">
                        <NavLink to={`/${app.lang}/registration`}>{app.localizationsItems?.register}</NavLink>
                    </li>}
        
                    {!app.registerByPhone && <li className="product-box-contain">
                        <NavLink to={`/${app.lang}/forgot-password`}>{app.localizationsItems?.forgot_password}</NavLink>
                    </li>}
                </ul>
            }
        </div>
    </li>
  )
})

export default UserIcon