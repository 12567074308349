import {makeAutoObservable} from "mobx";

export default class AppStore {
    constructor() {
        this._settings = []
        this._localizationsItems = {
            price: 'Цена',
            you_saved: 'Вы сэкономили',
            qty: 'Кол-во',
            general: 'Общее',
            action: 'Действие',
            delete: 'Удалить',
            currency: 'тг',
            total: 'Всего',
            checkout_process: 'Оформить заказ',
            go_back_shop: 'Вернуться к покупкам',
            total_cart: 'Итог заказа',
            delivery_variant: 'Вариант доставки',
            payment_variant: 'Способ оплаты',
            checkout: 'Оформить заказ',
            order_accepted: 'Заказ принят',
            order_content_1: 'Заказ успешно оформлен!',
            order_content_2: '',
            view_cart: 'Посмотреть корзину',
            to_check: 'Оформить',
            outcome: 'Итог',
            product_information: 'информация о продукте',
            popular_products: 'Популярные продукты',
            add: 'Добавить',
            information: 'Информация',
            favorites: 'Избранное',
            description: 'Описание',
            add_to_cart: 'Добавить в корзину',
            to_cart_text: 'Продукт, выбранный для',
            by_together: 'Вместе с этим часто покупают',
            learn_more: 'Узнать больше',
            recent_posts: 'Недавние посты',
            recent_projects: 'Последние проекты',
            recent_services: 'Последние услуги',
            recent_events: 'Последние мероприятия',
            hot_line: 'Горячая линия',
            information_shop: 'Информация о магазине',
            search_products: 'Поиск Продуктов',
            search_text: 'Поиск',
            categories: 'Категории',
            apply: 'Применить',
            back: 'Назад',
            reset: 'Сбросить',
            filter: 'Фильтр',
            buy_now: 'Купить сейчас',
            menu: 'Меню',
            banner_subscribe: 'Подписаться',
            add_to_favorites: 'Добавить в избранное',
            main: 'Главная',
            cart: 'Корзина',
            all_categories: 'Все категории',
            error_content: 'Страница, которую вы ищете, не может быть найдена. Ссылка на этот адрес может быть устаревшей или мы могли переместить ее с тех пор, как вы последний раз добавляли ее в закладки.',
            back_to_main: 'Вернуться на главную страницу',
            login: 'Войти',
            logout: 'Выйти',
            profile: 'Профиль',
            register: 'Зарегистрироваться',
            forgot_password: 'Забыли пароль',
            login_title: 'Добро пожаловать в Neomilk',
            login_content: 'Войдите в свою учетную запись',
            not_account: 'У вас нет аккаунта?',
            regis: 'Регистрация',
            email: 'Email',
            password: 'Пароль',
            regis_title: 'Добро пожаловать в Neomilk',
            regis_content: 'Создать новый аккаунт',
            fio: 'ФИО',
            confirm_password: 'Подтвердите пароль',
            have_account: 'У вас уже есть аккаунт?',
            basket: 'Корзина',
            forgot_title: 'Добро пожаловать в Neomilk',
            forgot_content: 'Забыли свой пароль',
            send_code: 'Отправить код',
            restore_password: 'Восстановление пароля',
            enter_code: 'Введите код',
            rest_pas: 'Восстановить пароль',
            cancel: 'Отмена',
            dashboard: 'Профиль',
            queue: 'Очередь',
            wishlist: 'Избранное',
            save_cart: 'Сохраненная карта',
            address: 'Адрес',
            confidentiality: 'Конфиденциальность',
            my_dashboard: 'Мой профиль',
            dashboard_content: 'На панели управления моей учетной записью у вас есть возможность просмотреть снимок недавней активности вашей учетной записи и обновить свою учетную запись Информация. Выберите ссылку ниже, чтобы просмотреть или изменить информацию.',
            orders: 'Заказы',
            account_info: 'Информация об аккаунте',
            edit: 'Редактировать',
            open_dashboard: 'Открыть панель',
            add_product: 'Продукт добавлен в корзину',
            wishlist_title: 'Пока избранных товаров нет',
            basket_title: 'Нет товаров в корзине',
            street: 'Улица',
            house: 'Номер дома',
            my_orders: 'Мои заказы',
            my_addresses: 'Мои адреса',
            add_new_address: 'Добавить новый адрес',
            name: 'ФИО',
            new_data: 'Данные обновлены',
            edit_profile: 'Редактировать профиль',
            phone: 'Номер телефона',
            save: 'Сохранить',
            delivery_address: 'Адрес доставки',
            add_address: 'Добавить адрес',
            address_added: 'Адрес добавлен',
            message: 'Комментарий к заказу',
            user_data: 'Данные пользователя',
            update_password: 'Обновление пароля',
            update_pas: 'Обновить пароль',
            new_password: 'Новый пароль',
            note: 'Примечание',
            city: 'Город',
            empty_data: 'Список пуст',
            availability: 'Доступность',
            in_stock: 'в наличии',
            not_available: 'нет в наличии',
            apartment: 'Квартира',
            entrance: 'Подъезд',
            floor: 'Этаж',
            edit_address: 'Редактировать адрес',
            delete_profile: 'Удалить профиль',
            order: 'Заказ',
            qty_goods: 'Количество товаров',
            all_text: 'Все',
            bonuses: 'Бонусы',
            discount: 'Скидка',
            use_bonuses: 'Использовать бонусы',
            bonuses_used: 'Использовано бонусов',
            subtotal: 'Промежуточный итог',
            order_number: 'Номер заказа',
            order_event: 'Событие',
            order_date: 'Дата',
            replenishment_bonuses: 'Пополнение бонусов',
            withdrawal_bonuses: 'Снятие бонусов',
            action_confirmation: 'Вы уверены, что хотите удалить свой профиль?',
            will_credited: 'Будет начислено',
            filter_text: 'Фильтр',
            minimal_sum_text: 'Минимальная сумма заказа:',
            restore_password_content: 'На вашу почту был отправлен код',
            order_error: 'Ошибка при оформление заказа',
            order_error_1: 'При оформление заказа произошла ошибка, с вами свяжется наш менеджер.',
            phone_check_send: 'На ваш номер был отправлен код',
            send: 'Отправить',
            recent_products: 'Вы недавно смотрели',
            delivery: 'Доставка',
            accrual_bonuses: 'Бонусы будут начислены после получения товара',
            free_delivery: 'До бесплатной доставка осталось',
            free: 'Бесплатно',
            send_message: 'Отправить',
            not_specified: 'Не указана',
            сancellations: 'Для отмены заказа просим обратиться в службу поддержки',
            product_delete_content: 'Вы уверены, что хотите удалить товар из корзины?',
            youWatched: 'Вы просматривали',
            notificationHistory: 'История уведомлений',
            freeDeliveryRemain: 'До бесплатной доставки осталось',
            bonusesAfterOrderDescription: 'Бонусы будут начислены после получения товара',
            emptyNotifications: 'У вас пока нету уведомлений',
            emptyUnreadNotifications: 'У вас нету новых уведомлений',
            emptyHouse: 'Укажите номер дома',
            emptyApartment: 'Укажите квартиру/офис',
            bonusDescription: 'Бонусы будут начислены после получения товара',
            select_city: 'Выберите ваш город',
            franchise: 'Франчайзи',
            franchise_title: 'Хотите стать частью NEOMILK?',
            franchise_content: 'Компания Neo Milk - Казахстанская компания, занимающаяся производством натуральных молочных продукции и занимающая лидирующие позиции на рынке Казахстана. Наши продукции имеют большой спрос, так как являются незаменимыми источниками витаминов и минералов, необходимых для иммунной системы.',
            franchise_content_2: 'Что нужно делать. Заполните данные вашей фирмы. Скачайте договор, ознакомьтесь, подпишите и прикрепите к заявке. Наш менеджер проанализирует. Вашу анкету и свяжется с Вами',
            personal_data: 'Личные данные',
            data_too: 'Данные ТОО',
            agreement: 'Договор',
            upload_download: 'Скачать договор',
            agreement_download: 'Загрузить подписанный договор',
            send_application: 'Отправить заявку',
            too_name: 'Название ТОО',
            iin_name: 'Номер ИИН',
            status: 'Статус',
            admin: 'Администратор',
            summa: 'Сумма',
            client: 'Клиент',
            statics: 'Статистика',
            city_required: 'Город обязателен для выбора',
            addresses: "Адреса",
            franchise_registration: "Регистрация франчайзи",
            history: 'История',
            free_coffee: "7-ой кофе бесплатно",
            coffee_cup: "чашек кофе",
            bonus_info:'Бонус — это дополнительное преимущество или вознаграждение, которое предоставляется клиентам в рамках программ лояльности или акций. В контексте покупки еды, бонус может быть предоставлен в виде скидки, накопительных баллов или специальных предложений.',
            bonus_title: 'Бонусы',
            free_coffee_title: "Бесплатный кофе",
            free_coffee_info: 'Бесплатный кофе начисляется как только клиент покупает 6 чашек кофе',
            qr_code: "QR-код",
            id_client: 'ID клиента',
            address_outside_of_delivery_zones: 'Адрес вне зоны доставки',
            product_min_amount: "Минимальное кол-во товара в заказе =",
            select_role: "Выберите роль",
            role_required: "Роль обязательня для выбора",
            delivery_time: "Время доставки",
            delivery_time_content: "Доставка и самовывоз осуществляются на следующий день после оформления заказа "

        }
        this._lang = localStorage.getItem('i18nextLng')
        this._languages = []
        this._userCabinet = true
        this._bonuses = false
        this._minimumOrderAmount = null
        this._registerByPhone = false
        this._city = null
        this._allCities = []
        makeAutoObservable(this)
    }

    setAllCity(cities) {
        this._allCities = cities
    }

    setCity(city) {
        localStorage.setItem('city', JSON.stringify(city));
        this._city = city
    }

    setRegisterByPhone(registerByPhone) {
        this._registerByPhone = registerByPhone
    }

    setSettings(settings) {
        this._settings = settings
    }

    setLocalizationsItems(localizationsItems) {
        this._localizationsItems = localizationsItems
    }

    setLang(lang) {
        localStorage.setItem('i18nextLng', lang)
        this._lang = lang
    }

    setLanguages(languages) {
        this._languages = languages
    }

    setUserCabinet(userCabinet) {
        this._userCabinet = userCabinet
    }

    setBonuses(bonuses) {
        this._bonuses = bonuses
    }

    setMinimumOrderAmount(minimumOrderAmount) {
        this._minimumOrderAmount = minimumOrderAmount
    }

    get city() {
        return JSON.parse(localStorage.getItem('city'));
    }

    get cityString() {
        return this._city
    }

    get allCities() {
        return this._allCities
    }

    get registerByPhone() {
        return this._registerByPhone
    }

    get settings() {
        return this._settings
    }

    get localizationsItems() {
        return this._localizationsItems
    }

    get lang() {
        return this._lang
    }

    get languages() {
        return this._languages
    }

    get userCabinet() {
        return this._userCabinet
    }

    get minimumOrderAmount() {
        return this._minimumOrderAmount
    }
    
    get bonuses() {
        return this._bonuses
    }

}