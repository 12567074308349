import React, { useContext } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/catalogServices';
import Utils from '../../services/utils';
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import { useRef } from 'react';
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const Search = observer(() => {
    const {app} = useContext(Context);
    const dropdownRef = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

    const [term, setTerm] = useState('');
    const navigate = useNavigate();

    const {
        items,
        loading,
        params,
        setFilterData
    } = useFetchItems(CatalogServices.products.index)

    const searchOn = () => {
        navigate(`/${app.lang}/search?term=${term}`);
        setIsActive(false)
    }

    const dynamicSearch = (e) => {
        setTerm(e.target.value);
        if(e.target.value.length >= 3){
            setFilterData(`?term=${term}`)
        }
        if(e.target.value.length <= 0){
            setFilterData(``)
        }
    }

    const navigateProduct = (card) => {
        navigate(`/${app.lang}/products/${card.slug}`)
        setIsActive(false)
    }

  return (
    <>
        <div className="search-full">
            <div className="input-group">
                <span className="input-group-text">
                    <i data-feather="search" className="font-light"></i>
                </span>
                <input type="text" className="form-control search-type" placeholder={app.localizationsItems?.search_products}
                    value={term} 
                    onChange={(e) => setTerm(e.target.value)}
                />
                <span className="input-group-text close-search" onClick={searchOn}>
                    <i data-feather="x" className="font-light"></i>
                </span>
            </div>
        </div>

        <div className={isActive ? "middle-box dropdown-visible" : "middle-box"} ref={dropdownRef}>
            <div className="center-box">
                <div className="searchbar-box order-xl-1 d-none d-xl-block">
                    <input type="text" className="form-control"
                        placeholder={app.localizationsItems?.search_products}
                        value={term} 
                        onChange={(e) => dynamicSearch(e)}
                        onClick={() => setIsActive(true)}
                    />
                    <button className="btn search-button" onClick={searchOn}>
                        <i className="iconly-Search icli"></i>
                    </button>
                </div>
            </div>
            <div className='dropdown-search'>
                {items?.items?.map((card, index) => 
                    <div key={index} className='dropdown-search-product' onClick={() => navigateProduct(card)}>
                        <img src={card.image} alt="" 
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                        />
                        <div className='title'>
                            {card.title}
                        </div>
                        {(!card.price || card.price == 0) ?
                            <div className='price theme-color'>{app.localizationsItems?.not_specified}</div>
                            :
                            <div className='price theme-color'>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</div>
                        }
                    </div>
                )}
            </div>
        </div>
    </>
  )
})

export default Search