import React from 'react'
import { NavLink } from 'react-router-dom'

const SubDropdownMenu2 = ({item, handleClose}) => {
  return (
    <li >
        <NavLink to={item.value} className='dropdown-item' onClick={handleClose}>{item.title}</NavLink>
        {item.items &&
            <ul className='ml-3'>
                {item.items.map((elem, index) =>
                    <SubDropdownMenu2 item={elem} key={index}/>
                )}
            </ul>
        }
    </li>
  )
}

export default SubDropdownMenu2