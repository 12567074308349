import React from 'react'
import { useState } from 'react';
import { NavLink } from 'react-router-dom'

const SubDropdownMenu = ({item}) => {
    const [visible, setVisible] = useState(false);

  return (
    <li className={item.items ? "sub-dropdown-hover" : ""} onMouseEnter={e => setVisible(true)} onMouseLeave={e => setVisible(false)}>
        <NavLink className="dropdown-item" to={item.value}>{item.title}</NavLink>
        {item.items &&
            <ul className={visible ? "sub-menu sub-menu-visible" : "sub-menu"}>
                {item.items.map((elem, index) =>
                    <SubDropdownMenu item={elem} key={index}/>
                )}
            </ul>
        }
    </li>
  )
}

export default SubDropdownMenu