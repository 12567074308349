import React from 'react'
import { NavLink } from 'react-router-dom'
import SubDropdownMenu2 from './SubDropdownMenu2'
import { useState } from 'react';

const MenuItem = ({it, handleClose}) => {
    const [open, setOpen] = useState();

  return (
    <li className="nav-item dropdown">
        {it.items ? 
            <div onClick={() => setOpen(!open)} className="nav-link dropdown-toggle">{it.title}</div>
            :
            <NavLink to={it.value} className="nav-link dropdown-toggle nav-none" onClick={handleClose}>{it.title}</NavLink>
        }
        {it.items &&
            <ul className={open ? 'dropdown-menu show' : 'dropdown-menu'}>
                {it.items.map((el, index) =>
                    <SubDropdownMenu2 key={index} item={el} handleClose={handleClose}/>
                )}
            </ul>
        }
    </li>
  )
}

export default MenuItem