import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import AppServices from '../../services/general/appServices';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const ApplicationModal = observer(({show, setShow}) => {
    const [visible, setVisible] = useState(false);
    const handleClose = () => setShow(false);

    const {app} = useContext(Context);

    const {handleSubmit, register, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        const res = await AppServices.request(data);
    
        if (res.statusCode === 200) {
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                handleClose();
            }, 3000);
        }
    };

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <form className='form-modal' onSubmit={handleSubmit(CustomSubmit)}>
                <h3 className='text-center'>{app.localizationsItems?.application_title}</h3>
                <p className='text-center'>{app.localizationsItems?.application_content}</p>
                <label className='form-label w-100'>
                    {app.localizationsItems?.name}
                    <input type="text" className='form-control' required {...register('name')} placeholder={app.localizationsItems?.name}/>
                </label>
                <label className='form-label w-100'>
                    {app.localizationsItems?.phone}
                    <input type="text" className='form-control' required {...register('phone')} placeholder={app.localizationsItems?.phone}/>
                </label>
                <button className='btn btn-animation btn-md fw-bold ms-auto'>{app.localizationsItems?.send}</button>
            </form>
            <div className={visible ? 'form-success form-visible' : 'form-success'}>
                <i className='fa fa-check-circle'></i>
                <h2>{app.localizationsItems?.form_success_title}</h2>
                <p>{app.localizationsItems?.form_success_text}</p>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default ApplicationModal