import React, { useContext } from 'react'
import { Offcanvas } from 'react-bootstrap'
import MenuItem from '../simple/MenuItem';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const MenuMobile = observer(({item, show, setShow}) => {
    const {app} = useContext(Context);
    const handleClose = () => setShow(false);

  return (
    <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky header-mobile">
        <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header className='navbar-shadow'>
                <h5>{app.localizationsItems?.menu}</h5>
                <button className="btn-close lead" type="button" onClick={handleClose}></button>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ul className="navbar-nav">
                    {item?.map((it, index) => 
                        <MenuItem it={it} key={index} handleClose={handleClose}/>
                    )}
                </ul>
            </Offcanvas.Body>
        </Offcanvas>
    </div>
  )
})

export default MenuMobile